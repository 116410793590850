import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import LazyLoad from 'react-lazy-load';
import { ThemeContext, themes } from '../../themes/ThemeContext';
import stylesNordr from './ArticleList.nordr.module.scss';
import stylesFolkhem from './ArticleList.folkhem.module.scss';

const getStyles = (theme) => ({
        [themes.NORDR]: stylesNordr,
        [themes.FOLKHEM]: stylesFolkhem,
    }[theme]
);

class ArticleList extends React.PureComponent {
    render() {
        const { title, items } = this.props;
        const theme = this.context;
        const styles = getStyles(theme);

        return (
            <div className={styles["ArticleList"]}>
                <h2 className={styles["ArticleList__Title"]}>{title}</h2>
                <ul className={styles["ArticleList__List"]}>
                    {items.map((item, index) => (
                        <li className={styles["ArticleList__Item"]} key={index}>
                            {item.image.renditions &&
                                item.image.renditions.standard800 && (
                                    <LazyLoad
                                        offset={400}
                                        throttle={150}
                                        debounce={false}
                                        className={classNames(
                                            styles["ArticleList__LazyImgWrapper"],
                                            // For targetig .is-active
                                            'ArticleList__LazyImgWrapper',
                                            {
                                                [styles["ArticleList__LazyImgWrapper--Link"]]:
                                                    item.link,
                                            }
                                        )}>
                                        {item.link ? (
                                            <a
                                                href={item.link.href}
                                                title={item.link.text}>
                                                <div
                                                    className={styles["ArticleList__ItemImage"]}
                                                    style={{
                                                        backgroundImage: `url(${
                                                            item.image
                                                                .renditions
                                                                .standard800.src
                                                        })`,
                                                    }}
                                                />
                                            </a>
                                        ) : (
                                            <div
                                                className={styles["ArticleList__ItemImage"]}
                                                style={{
                                                    backgroundImage: `url(${
                                                        item.image.renditions
                                                            .standard800.src
                                                    })`,
                                                }}
                                            />
                                        )}
                                    </LazyLoad>
                                )}
                            <h3 className={styles["ArticleList__ItemTitle"]}>
                                {item.title}
                            </h3>
                            <p className={styles["ArticleList__ItemText"]}>{item.text}</p>
                            {item.link && (
                                <a
                                    href={item.link.href}
                                    className={styles["ArticleList__ItemLink"]}>
                                    {item.link.text}
                                </a>
                            )}
                        </li>
                    ))}
                </ul>
            </div>
        );
    }
}

ArticleList.propTypes = {
    title: PropTypes.string,
    items: PropTypes.array,
};

ArticleList.defaultProps = {
    items: [],
};

ArticleList.contextType = ThemeContext;

export default ArticleList;
